import { Box, SkeletonCircle, SkeletonText } from '@mybridge/ui';
import { forwardRef } from 'react';
export const UserCardSkeleton = forwardRef(({ ...props }, ref) => {
  return (
    <>
      <Box p={2}>
        <SkeletonCircle size="10" />
        <SkeletonText mt="4" noOfLines={2} spacing="2" skeletonHeight="2" />
      </Box>
    </>
  );
});
