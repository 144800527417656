/* eslint-disable @nx/enforce-module-boundaries */
import React, { useContext } from 'react';
import {
  Avatar,
  Button,
  Divider,
  HStack,
  Heading,
  IconButton,
  Stack,
  Text,
  Card,
  VStack,
  Box,
} from '@mybridge/ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { getUserFullName, formatCommaSeparated } from 'v4/lib/commons';
import { MyNetworkContext } from 'v4/components/network/context';
import { UserNetworkingActions } from 'v4/components/user-networking-actions';

const SuggestionUserCardBox = ({ user, ...props }) => {
  const { profile_pic, experiences, educations, position, slug } = user;
  const {
    suggestions,
    allSuggestions,
    refetchSuggestions,
    suggestionsIsFetching,
    suggestionsIsLoading,
  } = useContext(MyNetworkContext);

  const router = useRouter();
  return (
    <Card variant="thinBorderedCard" p={3}>
      <VStack
        gap={3}
        alignItems="stretch"
        minH="260px"
        justifyContent="space-between"
      >
        <Link href={`/p/${slug}`}>
          <Avatar
            src={profile_pic}
            name={getUserFullName(user)}
            size="xl"
            m="0 auto"
          />
          </Link>
          <Link href={`/p/${slug}`}>
          <Text fontSize="md" fontWeight="700" color="brandPrimary.500">
            {getUserFullName(user)}
          </Text>
          </Link>
        <Box minH="64px">
          {experiences?.length ? (
            <Text fontSize="sm">
              {formatCommaSeparated([
                experiences?.[0]?.title,
                experiences?.[0]?.company_name,
              ])}
            </Text>
          ) : educations?.length ? (
            <Text fontSize="sm">{educations?.[0]?.name}</Text>
          ) : (
            <Text fontSize="sm">{position}</Text> ?? ''
          )}
        </Box>
        <UserNetworkingActions
          user={user}
          showHandshakeOnly
          justifyContent="stretch"
        />
      </VStack>
    </Card>
  );
};

export default SuggestionUserCardBox;
